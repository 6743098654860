<template>
  <main v-if="ListTeam.status != 'false'" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">
            <i class="fas fa-users margin-r" aria-hidden="true"></i>Edit Team
          </h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <h6 class="text-start card-title fw-bold"> Team Info </h6>
                <div class="container mt-4">
                  <form ref="TeamForm" @submit.prevent="PostEditTeam()" class="row g-3 justify-content-center align-items-center">
                    <div class="col-md-12" align="left">
                      <label for="validationDefault01" class="form-label">Name</label>
                      <input type="text" v-model="TeamPost.name" class="form-control" id="validationDefault01" required placeholder="Name of Team">
                    </div>
                    <div class="col-md-12">
                      <label class="form-label">Team color</label>
                      <input class="form-control" type="color" v-model="TeamPost.color" required />
                    </div>
                    <div id="team-info" v-if="GlobalTeamInfo.status != 'false'">
                    <hr>
                     <div class="d-flex  text-blue">
                      <h4 class="text-blue">Activity of team</h4>
                    </div>
                    <div class="d-flex m-3 text-blue text-shadow-1">
                      <div class="row m-0" align="left">
                         <h6 class="card-subtitle mb-2 text-blue">Time</h6>
                        <h4 class="card-title">{{GlobalTeamInfo.time}}</h4>
                      </div>
                      <div class="row m-0" align="left">
                        <h6 class="card-subtitle mb-2 text-blue">Activity</h6>
                        <h4 class="card-title">{{GlobalTeamInfo.activity}}</h4>
                      </div>
                      <div class="row m-0" align="left">
                        <h6 class="card-subtitle mb-2 text-blue">Earned</h6>
                        <h4 class="card-title">{{GlobalTeamInfo.earned}}</h4>
                      </div>
                    </div>
                    <hr>
                    </div>
                    <div class="d-flex justify-content-end mt-3 m-1">
                      <a class=" btn btn-danger"  style="margin-right: 5px;" @click="$router.go(-1)"> Go Back</a>
                      <button :disabled="Form.submit" class="button-1 btn btn-primary" type="submit">Save Team</button>
                    </div>
                  </form>
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Api from '@/services/Request'; 
import moment from 'moment-timezone'

  export default
  {
    name: 'ContentEditTeam',
    data: () => (
    {
      GlobalTeamInfo: 
      {
       status: "false",
       time: "",
       activity: "",
       earned: "",
      },
    
      TeamPostE: 
      {
       id_team: "",
       date: new Date().toISOString().slice(0, 10),
       timezone: moment.tz.guess(true),
      },
      TeamPost: 
      {
        id:"",
        name: "",
        color: "",
        status:"",
        message:""  
      },
      ListTeam: 
      {
        id:"",
        status:""
      },
      Form: 
      {
        submit: false
      }
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      PostEditTeam() 
      {
        Api.post("/team/edit",this.TeamPost).then((result) => 
        {
          this.Form.submit = true

          if(result.data.status == "true")
          {
            this.TeamPost.status = result.data.status
            this.TeamPost.message = result.data.msg;
         
            this.$moshaToast( ''+this.$filters.OnlyLetters(this.TeamPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
        
            setTimeout( () => 
            {
              this.TeamPost.status = ""
              this.Form.submit = false
            }, 5000);
          }
        })    
      },
      GetTeam() 
      {
        this.TeamPostE.id_team = this.$route.params.id

        Api.get('/team/list/' + this.$route.params.id).then((result) => 
        {
          this.ListTeam.status = result.data.status

          if(this.ListTeam.status == "true")
          {
            this.TeamPost.name =  result.data.team[0].name
            this.TeamPost.id =  result.data.team[0].id
            this.TeamPost.color =  result.data.team[0].color
          }
        })
      },
      GetTeamE() 
      {
        Api.post('teams-members/activity',this.TeamPostE).then((result) => 
        {
          this.ListTeam.status = result.data.status

          if(this.ListTeam.status == "true")
          {
            this.GlobalTeamInfo.time = result.data.time
            this.GlobalTeamInfo.activity = result.data.activity
            this.GlobalTeamInfo.earned = result.data.earned
            let count = result.data.list.length
        
            if(count != 0)
            {
              this.GlobalTeamInfo.status = "true"
            }
           
          }
        })
      }
    },
    mounted()
    {
      this.TeamPostE.date = this.ActualDate();
      
      this.GetTeam()
      this.GetTeamE()
    }    
  }
</script>

<style></style>
